import { BlitzPage } from "@blitzjs/next"
import { useRouter } from "next/router"
import { LoginForm } from "src/auth/components/LoginForm"
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"

const LoginPage: BlitzPage = () => {
  const router = useRouter()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  })

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 247px)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
    >
      <Container>
        <Grid container spacing={6}>
          {isMd ? (
            <Grid item container justifyContent={"center"} xs={12} md={6}>
              <Box height={1} width={1} maxWidth={500}>
                <Box component={"img"} src="/kmitl_bg.jpeg" width={1} height={1} />
              </Box>
            </Grid>
          ) : null}
          <Grid item container alignItems={"center"} justifyContent={"center"} xs={12} md={6}>
            <Box>
              <Box marginBottom={4}>
                <Typography
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "medium",
                  }}
                  gutterBottom
                  color={"text.secondary"}
                >
                  Login
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 500,
                  }}
                >
                  Course Syllabus
                </Typography>
              </Box>
              <LoginForm
                onSuccess={(_user) => {
                  const next = router.query.next
                    ? decodeURIComponent(router.query.next as string)
                    : "/"
                  router.push(next)
                }}
              />
              <Box>
                <Typography
                  align="center"
                  component="p"
                  variant="body1"
                  color="primary"
                  sx={{ mt: 4 }}
                >
                  Office of Academic Administration and Education Quality <br />
                  King Mongkut{"'"}s Institute of Technology Ladkrabang
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

LoginPage.redirectAuthenticatedTo = "/"

export default LoginPage
